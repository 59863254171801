@import "../../../styles/variables.module";

.container {
  max-width: 56rem;
  margin: 6.4rem auto;
  position: relative;
  text-align: center;

  h2 {
    color: $color-light;
    font-size: 4.8rem;
    font-weight: 700;
    margin: 0 0 3.2rem;
  }
}