@import "../../../styles/variables.module";

.rotate_bar {
  animation: barRotate 1s infinite;
  transform-origin: center center;
}

@keyframes barRotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
