@import "../../../styles/variables.module";

.wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  background-color: hsla(0, 0%, 10%, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  padding: 4.8rem;
  background-color: #001f3d;
  border-radius: $border-radius;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.title {
  font-size: 3.2rem;
  font-weight: 700;
  color: $color-light;
  margin-bottom: 3.2rem;
}

.action_wrapper {
  display: flex;
  justify-content: space-between;

  button {
    &:first-child {
      margin-right: 2.4rem;
    }
  }
}
body:global(.arrow-navigation) {
  @include media(">phoneXL") {
    .action_wrapper {
      button {
        &:focus {
          outline: none;
          background-color: rgba(93, 108, 146, 0.35);
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          transform: scale(1.05);
        }
      }
    }
  }
}
