@import "../../../styles/variables.module";

.footer {
  color: $color-light;
  padding: 6.4rem 1.6rem 4.8rem;
  margin: 0 auto;
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url("/gradientUpsideDown.jpg");
  background-repeat: repeat-x;
  background-position: center top, center bottom;

  &.no_background {
    background: none;
    width: 1rem;
  }

  @include media(">desktop720p") {
    padding: 6.4rem 3.4rem 4.8rem;
  }
  @include media("<tablet") {
    padding-bottom: 15rem;
    background-position: center top, center bottom;
  }

  .bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    menu {
      display: flex;
      flex-wrap: wrap;
      padding-left: 0.8rem;
      max-width: 100%;

      @include media("<tablet") {
        flex-direction: column;
        display: flex;
        width: calc(100vw - 3.2rem);
        padding-bottom: 4.8rem;
      }
    }

    a {
      display: inline-block;
      padding: 0.1rem 0;
      margin: 1.2rem 1rem 0.1rem 0;
      font-size: 1.5rem;

      @include media("<tablet") {
        margin-bottom: 1.6rem;
        font-size: 2rem;
      }
    }

    .social_button {
      svg {
        width: 2.2rem;
        height: 2.2rem;
      }

      &.facebook {
        width: 1rem;
      }
    }
    .social_button + .social_button {
      margin-left: 4rem;
    }
  }

  .header {
    font-size: 2.2rem;
    font-weight: 700;
    line-height: 3.3rem;
    letter-spacing: -0.02em;
    margin-bottom: 3.6rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    .logo {
      height: 3.3rem;
    }

    .prm {
      font-size: 2.4rem;

      @include media("<tablet") {
        display: none;
      }
    }
  }

  .stores {
    display: flex;
    margin-left: -0.8rem;
    margin-bottom: 2.4rem;

    a {
      order: 1;
      padding-left: 0.8rem;
    }

    @include media("<tablet") {
      align-items: center;
      flex-wrap: wrap;

      .old_website {
        order: 2;
        margin-top: 1rem;
      }
    }
  }

  .section {
    .bottom {
      margin-top: 5.2rem;
    }

    &.menu {
      svg {
        width: 14rem;
        height: 3.3rem;
      }

      p {
        font-size: 1.7rem;
        color: rgba($color-light, 0.5);
        line-height: 2rem;
        max-width: 53rem;
      }
    }

    .alt_button {
      border: 0.2rem solid $color-light;
      white-space: nowrap;
      font-size: 1.8rem;
      line-height: 2.5rem;
      max-width: 28.3rem;
      font-weight: 400;
      padding-left: 0.8rem;
      padding: 0.5rem 2.4rem;
      border-radius: $border-radius-small;

      @include media("<phoneXL") {
        font-size: 1.6rem;
        padding: 0.5rem 1.6rem;
      }
    }

    &.download {
      width: 44rem;
      max-width: 35%;
      align-items: center;

      @include media("<desktop720p") {
        text-align: right;
      }
    }
  }
}
