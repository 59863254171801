@import "../../../styles/variables.module";

body:global(.arrow-navigation) {
  @include media(">phoneXL") {
    .keycap {
      &:focus {
        outline: none;
        background-color: rgba(93, 108, 146, 0.35);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      }
    }
  }
}

.wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $color-black;
  padding: 1.6rem;
}

.row {
  display: flex;
  justify-content: space-between;
}

.keycap {
  margin: 0.4rem;
  padding: 1.6rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  background-color: #5c5c5c; //$color-primary;
  flex: 1;
  display: flex;
  justify-content: center;
  font-size: 3.6rem;
  color: $color-light;
}
